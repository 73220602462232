import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pr-2 flex-grow-1",staticStyle:{"max-width":"600px"}},[_c(VTextField,{attrs:{"outlined":"","label":"Search","hide-details":"","dense":"","clearable":""},on:{"input":_vm.onSearchQueryInput},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"pr-2"},[_c('forms-venues',{attrs:{"filter-query":_vm.extendedFilterQuery},on:{"input":_vm.emitChange},model:{value:(_vm.selectedVenues),callback:function ($$v) {_vm.selectedVenues=$$v},expression:"selectedVenues"}})],1),_c('div',{staticClass:"pr-2",staticStyle:{"max-width":"300px"}},[_c('forms-tags',{attrs:{"filter-query":_vm.extendedFilterQuery},on:{"input":_vm.emitChange},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),_c('div',{staticClass:"flex-grow-1"}),_c('div',[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1 pr-1"},[_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Between Dates","prepend-icon":"mdi-calendar","readonly":"","hide-details":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{staticClass:"ma-0 pa-0",staticStyle:{"height":"100%"},attrs:{"x-small":"","text":""},on:{"click":_vm.clearDateRange}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true}],null,true),model:{value:(_vm.dateRangeString),callback:function ($$v) {_vm.dateRangeString=$$v},expression:"dateRangeString"}},'v-text-field',attrs,false),on))],1)])]}}])},[_c(VDatePicker,{attrs:{"range":""},on:{"input":_vm.emitChange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="component">
    <v-card>
      <template v-if="isRetrieving">
        <div class="pa-5 d-flex justify-center align-center">
          <v-progress-circular indeterminate/>
        </div>
      </template>
      <template v-else>
        <v-img v-bind:src="event.eventPhoto" max-height="500px" position="top"/>
        <v-divider/>
        <v-card-text>
          <div class="d-flex">
            <div class="flex-grow-1">
              <h1 class="mt-1 mb-2">{{ event.eventName }}</h1>
              <h2 class="font-weight-light">{{ event.venue.name }}</h2>
            </div>
            <div class="text-right d-flex justify-center align-center">
              <div>
                <span class="event-date">
                {{ date.format('D MMM, YYYY') }}
              </span>
                <div>
                  {{ startDuration.format('h:mm A') }}
                  <template v-if="endDuration">
                    till {{ endDuration.format('h:mm A') }}
                  </template>
                  <span v-if="durationLength">
                  ({{ durationLength.format('H:mm') }})
                </span>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-subtitle>
          <template v-for="tag in event.tags">
            <v-chip small class="mr-1 mb-1">{{ tag.tag }}</v-chip>
          </template>
        </v-card-subtitle>
        <v-card-text v-if="event.eventDescription && event.eventDescription.trim().length > 0">
          <div class="pr-2" style="max-height: 300px; overflow: auto;" v-html="event.eventDescription"/>
        </v-card-text>
        <v-card-text v-if="event.eventLinkSecondary || event.eventLinkPrimary">
          <div class="d-flex">
            <div class="flex-grow-1">

            </div>
            <div class="d-flex">
              <div v-if="event.eventLinkSecondary" class="ml-2">
                <v-btn :href="event.eventLinkSecondary">Visit Event Site</v-btn>
              </div>

              <div v-if="event.eventLinkPrimary" class="ml-2">
                <v-btn color="primary" depressed target="_blank" :href="event.eventLinkPrimary">Visit Event Site</v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script type="text/babel">
import Api from "@/clients/api";
import _ from "lodash";
import moment from "moment";
import 'moment-duration-format';

export default {
  name: 'components-event_detail-index',
  watch: {
    eventKey() {
      this.retrieve();
    }
  },
  props: {
    eventKey: {
      required: true
    }
  },
  data() {
    return {
      isRetrieving: false,
      event: undefined
    }
  },
  methods: {
    retrieve() {
      this.isRetrieving = true;

      return Api.getEvents({
        query: {
          eventKey: this.eventKey
        },
        pageSize: 1
      })
          .then((results) => {
            this.event = _.get(results, 'data.results.0');
          })
          .finally(() => {
            this.isRetrieving = false;
          })
    },
  },
  computed: {
    startDuration() {
      if (!this.event.eventStartTime) {
        return;
      }
      let duration = moment.duration(this.event.eventStartTime);
      return moment.utc(duration.as('milliseconds'));
    },
    endDuration() {
      if (!this.event.eventEndTime) {
        return;
      }
      let duration = moment.duration(this.event.eventEndTime);
      return moment.utc(duration.as('milliseconds'));
    },
    durationLength() {
      if (!this.startDuration || !this.endDuration) {
        return;
      }

      return this.endDuration.subtract(this.startDuration);
    },
    date() {
      return moment(this.event.eventDate);
    }
  },
  created() {
    this.retrieve();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.event-date {
  font-weight: bold;
}
</style>

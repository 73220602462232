<template>
  <v-card v-bind:color="active ? 'blue darken-4' : 'blue darken-3'"
          v-bind:dark="true"
  >
    <v-card-text class="pa-0">
      <div class="d-flex">
        <div>
          <v-img v-bind:aspect-ratio="4/3" width="150" height="100%"
                 v-bind:src="item.eventPhoto ?? 'https://media.istockphoto.com/photos/arizona-desert-landscape-at-sunset-picture-id1209784438?b=1&k=20&m=1209784438&s=612x612&w=0&h=hr9Od1gy7rUJ83_AGXgktG2fQYCAwaIkp27PYj9YU_0='"/>
        </div>
        <div class="flex-grow-1 pa-4">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div style="padding-right: 50px; min-height: 50px;" class="pb-5">
                <span class="event-name pb-2">{{ item.eventName }}</span>
                <span class="venue-name d-flex align-center"><v-icon>mdi-map-marker</v-icon>{{ item.venue.name }}</span>
              </div>
              <template v-for="tag in item.tags">
                <v-chip color="blue darken-2" dark small class="mr-1 mb-1">{{ tag.tag }}</v-chip>
              </template>
            </div>
          </div>
          <div class="date">
            <span class="date-month">{{ itemDate.format('MMM') }}</span>
            <span class="date-number">{{ itemDate.format('D') }}</span>
            <div class="start-end-time">
              <span class="start-time" v-if="startDuration">{{ startDuration.format('h:mm A') }}</span>
              <span class="end-time" v-if="endDuration">{{ endDuration.format('h:mm A') }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script type="text/babel">
import moment from "moment";

export default {
  name: 'components-_boilerplate-index',
  watch: {},
  props: {
    active: undefined,
    item: {
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    startDuration() {
      if (!this.item.eventStartTime) {
        return;
      }
      let duration = moment.duration(this.item.eventStartTime);
      return moment.utc(duration.as('milliseconds'));
    },
    endDuration() {
      if (!this.item.eventEndTime) {
        return;
      }
      let duration = moment.duration(this.item.eventEndTime);
      return moment.utc(duration.as('milliseconds'));
    },
    itemDate() {
      return moment(this.item.eventDate);
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.event-name {
  display: block;
  font-size: 1.25em;
  line-height: 1.1em;
  font-weight: 300;
}

.venue-name {
  display: block;
}

.date {
  background-color: rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 10px;
  padding: 5px 5px 10px 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: center;
  color: white;
  width: 60px;
}

.date-month {
  font-size: .5em;
  font-weight: 300;
  display: block;
  text-transform: uppercase;
}

.date-number {
  font-size: 1.5em;
  font-weight: 300;
  display: block;
}

.start-end-time {
  padding-top: 15px;
  font-size: .5em;
  line-height: 1.2em;
  opacity: .8;
}

.end-time,
.start-time {
  display: block;
}
</style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"color":_vm.active ? 'blue darken-4' : 'blue darken-3',"dark":true}},[_c(VCardText,{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c(VImg,{attrs:{"aspect-ratio":4/3,"width":"150","height":"100%","src":_vm.item.eventPhoto ?? 'https://media.istockphoto.com/photos/arizona-desert-landscape-at-sunset-picture-id1209784438?b=1&k=20&m=1209784438&s=612x612&w=0&h=hr9Od1gy7rUJ83_AGXgktG2fQYCAwaIkp27PYj9YU_0='}})],1),_c('div',{staticClass:"flex-grow-1 pa-4"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"pb-5",staticStyle:{"padding-right":"50px","min-height":"50px"}},[_c('span',{staticClass:"event-name pb-2"},[_vm._v(_vm._s(_vm.item.eventName))]),_c('span',{staticClass:"venue-name d-flex align-center"},[_c(VIcon,[_vm._v("mdi-map-marker")]),_vm._v(_vm._s(_vm.item.venue.name))],1)]),_vm._l((_vm.item.tags),function(tag){return [_c(VChip,{staticClass:"mr-1 mb-1",attrs:{"color":"blue darken-2","dark":"","small":""}},[_vm._v(_vm._s(tag.tag))])]})],2)]),_c('div',{staticClass:"date"},[_c('span',{staticClass:"date-month"},[_vm._v(_vm._s(_vm.itemDate.format('MMM')))]),_c('span',{staticClass:"date-number"},[_vm._v(_vm._s(_vm.itemDate.format('D')))]),_c('div',{staticClass:"start-end-time"},[(_vm.startDuration)?_c('span',{staticClass:"start-time"},[_vm._v(_vm._s(_vm.startDuration.format('h:mm A')))]):_vm._e(),(_vm.endDuration)?_c('span',{staticClass:"end-time"},[_vm._v(_vm._s(_vm.endDuration.format('h:mm A')))]):_vm._e()])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- Please remove this file from your project -->
<template>
  <div id="map"></div>
</template>

<script>
import 'leaflet/dist/leaflet.css';


export default {
  name: 'Map',
  data() {
    return {
      L: undefined,
      focusIcon: undefined,
      whiteIcon: undefined,
      blueIcon: undefined,
      focusMarker: undefined,
      show: false,
      eventMarkers: [],
      currentEventKeys: []
    };
  },
  props: {
    events: {
      required: true
    },
    startingBounds: undefined,
    focusedEvent: undefined
  },
  watch: {
    focusedEvent() {
      this.addFocusMarker(this.focusedEvent);
      this.focusedOnFocusedEvent();
    },
    events() {
      this.setEventMarkers();
    }
  },
  methods: {
    focusedOnFocusedEvent() {
      if (this.focusedEvent.geodata) {
        this.focusOn({
          lat: this.focusedEvent.geodata.latitude,
          long: this.focusedEvent.geodata.longitude
        })
      }
    },
    addEventMarker(event) {
      let latLong = [
        event.venue.address.lnglat.coordinates[1],
        event.venue.address.lnglat.coordinates[0]
      ];
      let marker = L.marker(latLong, {
        icon: this.blueIcon
      });

      marker.meta = {};

      marker.meta.event = event;
      return marker;
    },
    clearEventMarkers(excludeEventKeys = []) {
      let newEventMapMarkers = [];
      this.eventMarkers.forEach((marker) => {
        let eventKey = marker.meta.event.eventKey;

        if (excludeEventKeys.indexOf(eventKey) === -1) {
          this.map.removeLayer(marker);
        } else {
          newEventMapMarkers.push(marker);
        }
      });
      this.eventMarkers = newEventMapMarkers;

      return Promise.resolve();
    },
    setEventMarkers() {
      let newEventKeys = this.events.map((event) => {
        return event.eventKey;
      })

      let excludeEventKeys = this.currentEventKeys.filter((eventKey) => {
        return newEventKeys.indexOf(eventKey) > -1;
      });

      this.clearEventMarkers(excludeEventKeys)
          .then(() => {
            this.events.forEach((event) => {
              if (excludeEventKeys.indexOf(event.eventKey) > -1) {
                return;
              }

              let marker = this.addEventMarker(event);
              marker.addTo(this.map);
              this.eventMarkers.push(marker);
            });
          })
          .then(() => {
            this.currentEventKeys = newEventKeys;
          })

      return Promise.resolve();
    },
    focusOn(latLong, zoom = 20) {
      this.map.setView([
        latLong.lat,
        latLong.long
      ], zoom);
    },
    clearFocusMarker() {
      if (this.focusMarker) {
        this.map.remove(this.focusMarker);
        this.focusMarker = undefined;
      }
    },
    addFocusMarker(event) {
      let latLong = {
        lat: event.geodata.latitude,
        long: event.geodata.longitude
      };

      this.clearFocusMarker();
      this.focusMarker = L.marker([
        latLong.lat,
        latLong.long
      ], {
        icon: this.focusIcon
      });

      this.focusMarker.addTo(this.map);
    },
    init() {
      let _this = this;
      this.map = this.L.map('map', {
        zoomControl: false
      });
      this.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
      }).addTo(this.map);

      if (this.startingBounds) {
        console.log('starting bounds', this.startingBounds);

        let bounds = [
          [
            this.startingBounds._northEast.lat, this.startingBounds._northEast.lng
          ],
          [
            this.startingBounds._southWest.lat, this.startingBounds._southWest.lng
          ]
        ];

        this.map.fitBounds(bounds);
      } else {
        this.focusOn({
          lat: 33.636570,
          long: -111.889648
        }, 10);
      }

      this.focusIcon = this.L.icon({
        iconUrl: '/markers/marker_green.png'
      });
      this.whiteIcon = this.L.icon({
        iconUrl: '/markers/marker_white.png'
      });

      this.blueIcon = this.L.icon({
        iconUrl: '/markers/marker_blue.png'
      });

      this.emitBounds();
      this.map.on('moveend', () => {
        _this.emitBounds();
      });
    },
    emitBounds() {
      this.$emit('bounds', this.map.getBounds());
    },
  },
  created() {
    console.log('startingBounds', this.startingBounds);
  },
  mounted() {
    this.L = require('leaflet');
    this.init();
    this.setEventMarkers();
    if (this.focusedEvent) {
      this.focusedOnFocusedEvent();
      this.addFocusMarker(this.focusedEvent);
    }
  },
}
</script>

<style lang="scss">
.date {
  font-size: 1.5em;
  line-height: 100%;
}

.month {
  font-size: .75em;
}
</style>

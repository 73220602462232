<template>
  <div>
    <v-autocomplete
        chips
        clearable
        deletable-chips
        v-model="newValue"
        v-on:change="onChange"
        :items="options"
        label="Tags"
        outlined
        dense
        hide-details
        multiple
        :allow-overflow="false"
        :loading="isRetrieving"
        v-bind:filter="autocompleteFilter"
    >
      <template v-slot:item="$props">
        {{ $props.item.text.tag }} ({{ $props.item.text.eventsFiltered }}/{{ $props.item.text.eventsTotal }})
      </template>

      <template v-slot:selection="$props">
        {{ $props.item.text.tag }} ({{ $props.item.text.eventsFiltered }}/{{ $props.item.text.eventsTotal }})
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import ApiClient from '@/clients/api'

export default {
  name: 'components-forms-tags-index',
  data() {
    return {
      isRetrieving: false,
      result: [],
      newValue: undefined,
    }
  },
  props: {
    filterQuery: undefined,
    value: undefined
  },
  watch: {
    filterQuery() {
      this.retrieve();
    }
  },
  methods: {
    autocompleteFilter(item, queryText, itemText) {
      queryText = queryText.toLowerCase();
      return item.text.tag.toLowerCase().indexOf(queryText) > -1;
    },
    onChange(e) {
      this.$emit('input', e);
      console.log(e);
    },
    retrieve() {
      this.isRetrieving = true;

      return ApiClient.getTagsWithCount({
        query: this.filterQuery
      })
          .then((result) => {
            this.result = result;
          })
          .catch((e) => {

          })
          .finally(() => {
            this.isRetrieving = false;
          })
    }
  },
  computed: {
    options() {
      let selected = [];
      let other = [];

      this.result.forEach((item) => {
        if (this.value.indexOf(item.key) > -1) {
          selected.push(item);
        } else {
          other.push(item);
        }
      });

      let result = [
        ...selected,
        ...other
      ];

      return result.map((item) => {
        return {
          value: _.get(item, 'key'),
          text: item,
        }
      });
    }
  },
  mounted() {

  },
  created() {
    this.newValue = Object.assign([], this.value);
    this.retrieve();
  },
  components: {}
}
</script>

<style lang="scss">

</style>

<template>
  <div>
    <v-autocomplete
        chips
        deletable-chips
        v-on:change="onChange"
        v-model="newValue"
        :items="options"
        label="Venues"
        outlined
        clearable
        dense
        hide-details
        multiple
        v-bind:filter="autocompleteFilter"
        :loading="isRetrieving"
    >
      <template v-slot:item="$props">
        {{ $props.item.text.name }} ({{ $props.item.text.eventsFiltered }}/{{ $props.item.text.eventsTotal }})
      </template>

      <template v-slot:selection="$props">
        {{ $props.item.text.name }} ({{ $props.item.text.eventsFiltered }}/{{ $props.item.text.eventsTotal }})
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import ApiClient from '@/clients/api'

export default {
  name: 'components-forms-venues-index',
  data() {
    return {
      isRetrieving: false,
      result: [],
      newValue: undefined,
    }
  },
  watch: {
    filterQuery() {
      this.retrieve();
    }
  },
  props: {
    filterQuery: undefined,
    value: undefined
  },
  methods: {
    autocompleteFilter(item, queryText, itemText) {
      queryText = queryText.toLowerCase();
      return item.text.name.toLowerCase().indexOf(queryText) > -1;
    },
    onChange(e) {
      this.$emit('input', e);
      console.log(e);
    },
    retrieve() {
      this.isRetrieving = true;

      return ApiClient.getVenuesWithCounts({
        options: {
          'name': 1,
          'key': 1
        },
        query: this.filterQuery
      })
          .then((result) => {
            let selected = [];
            let other = [];

            result.forEach((item) => {
              if (this.value.indexOf(item.key) > -1) {
                selected.push(item);
              } else {
                other.push(item);
              }
            });

            result = [
              ...selected,
              ...other
            ];

            this.result = result;
          })
          .catch((e) => {

          })
          .finally(() => {
            this.isRetrieving = false;
          })
    }
  },
  computed: {
    options() {
      return this.result.map((item) => {
        return {
          value: _.get(item, 'key'),
          text: item
        }
      });
    }
  },
  mounted() {

  },
  created() {
    this.newValue = Object.assign([], this.value);
    this.retrieve();
  },
  components: {}
}
</script>

<style lang="scss">

</style>

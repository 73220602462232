<template>
  <div style="height: 100%" class="d-flex flex-column">
    <v-app-bar
        app
        color="primary darken-2"
        dark
        dense
        style="z-index: 9100;"
        class="py-1"
        height="70"
    >
      <filters v-on:input="execute"
               style="width: 100%;"
               v-bind:extended-filter-query="extendedFilterQuery"
               v-model="filterQuery"/>
    </v-app-bar>

    <v-main>

      <div class="d-flex" style="height: 100%;">
        <div style="width:30%; min-width: 400px; max-width: 500px; position: relative;">
          <v-sheet color="blue lighten-5"
                   :elevation="5"
                   style="position: absolute; top: 0px; right: 0; bottom: 0; left: 0; overflow: auto; z-index: 9001"
                   class="pa-2 px-4"
               v-auto-animate>
            <template v-for="event in listEvents">
              <div v-on:click="() => onClickListItem(event)">
                <list-item class="my-2"
                           style="cursor: pointer"
                           v-bind:active="event.eventKey === routeEventKey"
                           v-bind:item="event"
                           v-bind:key="event.eventKey"
                />
              </div>
            </template>
            <div v-if="listEvents.length > 0" v-intersect="reachedEventListBottom"/>
            <div class="text-center">
              <v-progress-circular indeterminate v-if="listEventInfiniteScrollPromise"/>
            </div>
          </v-sheet>
        </div>
        <div style="position: relative; height: 100%;" v-if="routeEventKey">
          <div style="position: absolute; left: 0; top: 0; bottom: 0; z-index: 9000; min-width: 600px; max-width: 1000px;">
            <div style="height: 100%;" class="pa-4">
              <div style="position: relative;">
                <event-detail v-bind:event-key="routeEventKey" />
                <div class="pa-2" style="position: absolute; right: 0; top: 0;">
                  <v-btn color="white" small depressed fab v-on:click="closeEvent"><v-icon>mdi-close</v-icon></v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <event-map ref="map"
                     v-bind:events="mapEvents"
                     v-bind:starting-bounds="eventMapBounds"
                     v-on:bounds="onEventMapBounds"
                     style="height: 100%;"/>
        </div>
      </div>
    </v-main>
<!--    <v-footer style="z-index: 9002; position: relative;">-->
<!--      Here-->
<!--    </v-footer>-->
  </div>
</template>

<script type="text/babel">
import Filters from "@/components/filters";
import ListItem from "@/components/list-item";
import EventMap from '@/components/event-map'
import EventDetail from "@/components/event-detail";
import Api from "@/clients/api";
import _ from "lodash";
import moment from "moment/moment";

export default {
  name: 'components-_boilerplate-index',
  watch: {},
  props: {},
  data() {
    return {
      filterQuery: undefined,
      listEvents: [],
      mapEvents: [],
      eventMapBounds: undefined,
      listEventsPage: 0,
      listEventsPages: 0,
      listEventInfiniteScrollPromise: undefined
    }
  },
  methods: {
    closeEvent() {
      let route = Object.assign({}, this.$route);
      route.params = {};
      this.$router.push(route);
    },
    onClickListItem(event) {
      console.log(event, this.$route.query);
      this.$router.push({
        to: 'events',
        query: this.$route.query,
        params: {
          eventKey: event.eventKey
        }
      })
    },
    reachedEventListBottom() {
      console.log('reachedEventListBottom');
      if (this.listEventInfiniteScrollPromise) {
        return this.listEventInfiniteScrollPromise;
      }

      if (this.listEventsPage >= this.listEventsPages) {
        return Promise.resolve();
      }

      let nextPage = this.listEventsPage + 1;

      this.listEventInfiniteScrollPromise = this.getListEvents(nextPage, this.listEvents)
          .finally(() => {
            let _this = this;
            setTimeout(() => {
              _this.listEventInfiniteScrollPromise = undefined;
            }, 1000);
          });

      return this.listEventInfiniteScrollPromise;
    },
    onEventMapBounds(bounds) {
      this.eventMapBounds = bounds;
      let query = Object.assign({}, this.$route.query);
      query.eventMapBounds = JSON.stringify(bounds);

      this.$router.replace({
        to: 'events',
        query,
        params: this.$route.params
      })

      this.execute();
    },
    getMapEvents(currentEvents = []) {
      return Api.getEvents({
        pageSize: 1000,
        options: {
          'eventName': 1,
          'eventKey': 1,
          'venue.address.lnglat': 1
        },
        query: Object.assign({}, this.extendedFilterQuery),
      })
          .then((result) => {
            this.mapEvents = [
              ...currentEvents,
              ..._.get(result, 'data.results', [])
            ];
          })
    },
    getListEvents(page = 1, currentEvents = []) {
      return Api.getEvents({
        page,
        pageSize: 25,
        options: {
          'eventKey': 1,
          'eventName': 1,
          'eventPhoto': 1,
          'eventDate': 1,
          'eventStartTime': 1,
          'eventEndTime': 1,
          'venue': 1,
          'tags': 1,
        },
        query: Object.assign({}, this.extendedFilterQuery),
        sort: {
          "eventDate": 1
        }
      })
          .then((result) => {
            this.listEventsPage = _.get(result, 'data.page', 1);
            this.listEventsPages = _.get(result, 'data.pages', 1);
            this.listEvents = [
              ...currentEvents,
              ..._.get(result, 'data.results', [])
            ];
          })
    },
    execute() {
      if (!this.eventMapBounds) {
        return;
      }

      return Promise.all([
        this.getMapEvents(),
        this.getListEvents()
      ])
          .then(() => {
            console.log('done');
          })
    }
  },
  computed: {
    extendedFilterQuery() {
      if(!this.eventMapBounds) {
        return {};
      }

      return Object.assign({
        $expr: {
          '$gte': [
            "$eventDate",
            {
              "$dateFromString": {
                "dateString": moment().toDate()
              }
            }
          ]
        },
        duplicate: {$ne: true},
        'venue.address.lnglat':
            {
              $geoWithin:
                  {
                    $box: [
                      [this.eventMapBounds._southWest.lng, this.eventMapBounds._southWest.lat],
                      [this.eventMapBounds._northEast.lng, this.eventMapBounds._northEast.lat]
                    ]
                  }
            },
      }, this.filterQuery);
    },
    routeEventKey() {
      return this.$route.params.eventKey;
    },
    routeEventMapBounds() {
      return this.$route.query.eventMapBounds;
    },
  },
  created() {
    if(this.routeEventMapBounds) {
      this.eventMapBounds = JSON.parse(this.routeEventMapBounds);
    }
  },
  mounted() {
    // this.execute();
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    EventDetail,
    EventMap,
    Filters,
    ListItem
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

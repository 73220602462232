import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

Vue.config.productionTip = false
Vue.use(autoAnimatePlugin);

new Vue({
    router,
    store,
    vuetify,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')

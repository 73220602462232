import axios from 'axios';

let path = `/api`;
const Service = {
    getVenues(params) {
        return axios.post(`${path}/venues`, params)
            .then((res) => {
                return res.data;
            })
    },
    getVenuesWithCounts(params) {
        return axios.post(`${path}/venues/with-counts`, params)
            .then((res) => {
                return res.data;
            })
    },
    getEvents(params) {
        return axios.post(`${path}/events`, params)
            .then((res) => {
                return res.data;
            })
    },
    getTags(params) {
        return axios.post(`${path}/tags`, params)
            .then((res) => {
                return res.data;
            })
    },
    getTagsWithCount(params) {
        return axios.post(`${path}/tags/with-counts`, params)
            .then((res) => {
                return res.data;
            })
    }
};


export default Service;

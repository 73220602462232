<template>
  <div class="component">
    <div class="d-flex">
      <div class="pr-2 flex-grow-1" style="max-width: 600px">
        <v-text-field v-model="searchQuery"
                      outlined
                      label="Search"
                      hide-details
                      dense
                      clearable
                      v-on:input="onSearchQueryInput"/>
      </div>
      <div class="pr-2">
        <forms-venues v-on:input="emitChange"
                      v-bind:filter-query="extendedFilterQuery"
                      v-model="selectedVenues"/>
      </div>
      <div class="pr-2" style="max-width: 300px">
        <forms-tags v-on:input="emitChange"
                    v-bind:filter-query="extendedFilterQuery"
                    v-model="tags"/>
      </div>
      <div class="flex-grow-1"></div>
      <div>
        <v-menu
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex">
              <div class="flex-grow-1 pr-1">
                <v-text-field
                    v-model="dateRangeString"
                    label="Between Dates"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    dense
                    outlined
                >
                  <template v-slot:append>
                    <v-btn x-small style="height: 100%" class="ma-0 pa-0" text v-on:click="clearDateRange">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
          </template>
          <v-date-picker
              v-model="dateRange"
              range
              v-on:input="emitChange"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import FormsVenues from "@/components/forms/venues";
import FormsTags from "@/components/forms/tags";
import moment from "moment";
import _debounce from 'lodash/debounce'

export default {
  name: 'components-filters-index',
  watch: {},
  props: {
    extendedFilterQuery: undefined,
    value: undefined
  },
  data() {
    return {
      searchQuery: undefined,
      tags: [],
      selectedVenues: [],
      dateRange: [
        moment().format('YYYY-MM-DD'),
        moment().add(1, 'week').format('YYYY-MM-DD'),
      ]
    }
  },
  methods: {
    onSearchQueryInput: _debounce(function () {
      console.log('Debounce button clicked!', this)
      this.emitChange();
    }, 1000),
    clearDateRange() {
      this.dateRange = [];
      this.emitChange();
    },
    setQuery() {
      let query = Object.assign({}, this.$route.query);
      query.filter = JSON.stringify({
        tags: this.tags,
        selectedVenues: this.selectedVenues,
        dateRange: this.dateRange,
        searchQuery: this.searchQuery
      });

      let newRoute = {
        path: this.$route.path,
        query
      };
      let newRouteResolved = this.$router.resolve(newRoute);

      if (this.$route.fullPath !== newRouteResolved.href) {
        this.$router.replace(newRoute);
      }
    },
    emitChange() {
      this.setQuery();
      this.$emit('input', this.query);
    },
    setFilterFromQuery() {
      let queryFilters = this.$route.query.filter ? JSON.parse(this.$route.query.filter) : {};
      if (queryFilters.tags) {
        this.tags = queryFilters.tags;
      }

      if (queryFilters.selectedVenues) {
        this.selectedVenues = queryFilters.selectedVenues;
      }

      if (queryFilters.selectedVenues) {
        this.selectedVenues = queryFilters.selectedVenues;
      }
      if (queryFilters.dateRange) {
        this.dateRange = queryFilters.dateRange;
      }
      if (queryFilters.searchQuery) {
        this.searchQuery = queryFilters.searchQuery;
      }

      this.emitChange();
    }
  },
  computed: {
    dateRangeString() {
      let string = '';

      if (this.dateRange.length === 0) {
        return;
      }

      if (this.dateRange[0]) {
        string = moment(this.dateRange[0]).format('MMM DD');
      }

      if (this.dateRange[1]) {
        string = `${string} to ${moment(this.dateRange[1]).format('MMM DD')}`;
      }

      return string;
    },
    query() {
      let $and = [];
      let parts = {};
      if (this.selectedVenues.length > 0) {
        $and.push({
          'venue.key': {
            '$in': this.selectedVenues
          }
        })
      }

      if (this.dateRange.length > 0) {
        if (this.dateRange[0]) {
          $and.push({
            $expr: {
              '$gte': [
                "$eventDate",
                {
                  "$dateFromString": {
                    "dateString": moment(this.dateRange[0]).toDate()
                  }
                }
              ]
            }
          });
        }

        if (this.dateRange[1]) {
          $and.push({
            $expr: {
              '$lte': [
                "$eventDate",
                {
                  "$dateFromString": {
                    "dateString": moment(this.dateRange[1]).toDate()
                  }
                }
              ]
            }
          });
        }
      }

      if (this.tags.length > 0) {
        $and.push({
          'tags.key': {
            '$all': this.tags
          }
        })
      }

      let queryParts = this.searchQuery && this.searchQuery.length > 0 ? this.searchQuery.trim().split(' ') : [];
      if (queryParts.length > 0) {
        let $or = [];

        let queryTags = queryParts.map((part) => {
          return part.toLowerCase()
        });

        $or.push({
          'tags.key': {
            '$all': queryTags
          }
        })

        $and.push({
          $or
        });
      }

      if ($and.length > 0) {
        parts['$and'] = $and;
      }

      return parts;
    }
  },
  created() {
    this.setFilterFromQuery();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    FormsVenues,
    FormsTags
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
